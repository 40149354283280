<template>
  <div>
    <div
      class="middleAnimateArea"
      :style="{ animation: animation, '-ms-animation': animation,
                '-moz-animation':animation,'-webkit-animation':animation,'width':bWidth,'height':bHeight}">
      <div
        style="z-index: 100; position: absolute; top: 0; background:rgba(255,255,255,0.6); width:100%; height: 25px; line-height: 25px; text-align: center">
        <a
          style="cursor: pointer;"
          @click="openSingleLadderMonitor()">{{name}}</a>
      </div>
      <div
        style="z-index: 101; position: absolute; top: 30px; right:40px; width:100px; height: 100px; line-height: 100px; text-align: center; color: #76c2f7; font-size: 40px; font-weight: bold;text-shadow: 2px 2px 2px #666;">
        {{currenStatus}}
      </div>
      <div
        style="z-index: 102; position: absolute; top: 150px; right:40px; width:150px; height: 150px; line-height: 150px; text-align: center; color: #76c2f7; font-size: 90px; font-weight: bold;text-shadow: 2px 2px 2px #666;">
        <span>{{Car_Position}}</span>
      </div>
      <div
        style="z-index: 103; position: absolute; top: 200px; left:40px; width:80px; height: 80px; line-height: 80px; text-align: center; ">
        <img :src="bigSrcDownUp"/>
      </div>
      <el-button
        style="position: absolute; top: 25px; left:0px;"
        type="primary"
        :icon="isSilence ? 'el-icon-bell':'el-icon-close-notification'"
        @click="changeMute">
      </el-button>
      <div class="middleElevatorBox">
        <img src="/static/images/elevator/eboxs2.png"/>
        <div class="middlleLayerArrow"><img v-if="srcDownUp" width="12px" :src="srcDownUp"/></div>
        <div class="middleLayerNum">
          <div style="z-index: 23">
            <span>{{Car_Position}}</span>
          </div>
        </div>
        <div
          v-if="connectState === '连接断开' || tValue === 'A08' || tValue === 'A10'
            ||tValue === 'A06' || tValue === 'A05'|| tValue === 'A11'"
          class="middleBrokenClass">
          <img src="/static/images/elevator/broken1/brokenbg_m.png"/>
        </div>
        <div v-if="connectState === '连接断开'" class="middleUnlinkClass">
          <img src="/static/images/elevator/broken1/unlink_m.png"/>
        </div>
        <div v-if="connectState === '连接断开'" class="middleStatusButton">
          <el-button
            style="background-image:url(/static/images/elevator/relink.png);background-repeat: no-repeat;background-size: 100% 100%;width: 120px;height: 45px"
            @click="reConnect()">
            <span v-if="$t('lang') === 'zh'" style="font-size: 16px;margin-left: 20%;">
              重连
            </span>
            <span v-else style="display: block;margin-left: 25%;transform:scale(0.9); font-size: 12px;">
              Reconnection
            </span>
          </el-button>
        </div>
        <div v-if="tValue === 'A08'">
          <span class="middleTValueClass">
            {{$t("monitor.a08Describe")}}
          </span>
        </div>
        <div v-if="tValue === 'A10'">
          <span class="middleTValueClass">
            {{$t("monitor.a10Describe")}}
          </span>
        </div>
        <div v-if="tValue === 'A11'">
          <span class="middleTValueClass">
            {{$t("monitor.a11Describe")}}

          </span>
        </div>
        <div v-if="tValue === 'A06'">
          <span class="middleTValueClass">
            {{$t("monitor.a06Describe")}}
          </span>
        </div>
        <div v-if="tValue === 'A05'">
          <span class="smallTValueClass">
            {{$t("monitor.a05Describe")}} {{beforeNum}}
          </span>
        </div>
        <div v-if="tValue === 'A04'">
          <img class="middleLoadingClass" src="/static/images/elevator/loading3.gif"/>
        </div>
        <div v-if="connectState === '连接断开'">
          <span class="middleStatusName">
            {{$t("monitor.connectionDisconnection")}}
          </span>
        </div>
        <div class="middleBox" :style="{left: (layout === 1 ? '90px' : '45px')}">
          <div class="middleBoxImg">
            <ul>
              <li v-show="isShowDoor">
                <a href="#" style="background:url(/static/images/elevator/door1s.png)"></a>
              </li>
              <li v-show="isShowDoor"><a href="#" style=""></a></li>
            </ul>
          </div>
        </div>
      </div>
      <!--电梯故障-->
      <div v-show="faultImg" class="middleElevatorBox" style="z-index: 30">
        <img style="width:293px;height:387px;" src="/static/images/elevator/ebox_alarmrd.png"/>
        <audio
          v-if="faultImg && !isSilence"
          id="faultMp3"
          src="/static/audio/Alarm.mp3"
          loop="loop"
          autoplay></audio>
      </div>
      <!--电梯故障+困人-->
      <div v-show="passengerImg" class="middleElevatorBox" style="z-index: 30">
        <img style="width:293px;height:387px;" src="/static/images/elevator/ebox_insbrted.png"/>
        <audio
          v-if="passengerImg && !isSilence"
          id="passengerMp3"
          src="/static/audio/Alarm.mp3"
          autoplay
          loop="loop"></audio>
      </div>
      <div class="alarmImage" v-if="alarmBell === 1">
        <img src="../../assets/alarm@2x.png" style="width: 48px;height: 48px;"/>
      </div>
    </div>
  </div>
</template>
<script>
  const wsUrl = window.config.wsUrl;

  //  test
  //  const wsUrl = 'ws://192.168.106.48:9090/websocket'
  const reconnectInterval = 5000; // 重试间隔时间,5秒
  const heartbeatTime = 9000; // 心跳间隔时间,90秒
  const pingProto = JSON.stringify({type: "101"}); // ping消息
  export default {
    components: {},
    /* eslint-disable */
    props: ["registerNo", "name", "bWidth", "bHeight", "clickTag", "layout","monitorLimit"],
    data() {
      return {
        isSilence: false,
        faultImg: false,
        passengerImg: false,
        passengerStatus: false, // 轿厢内是否有人
        Fault_Status: false,
        ws: null,
        //        电梯当前楼层
        Car_Position: "",
        //        轿厢运行方向   0：无方向 1：上行  2：下行
        Car_Direction: "",
        //        True：关门到位 False：无关门到位信号关门：关门到位 开门：无关门到位信号
        Door_Status: "",
        //        门处于开门还是关门状态 0：关；1：开
        Door_Switch_Status: "",
        srcDownUp: "",
        bigSrcDownUp: "",
        animation: "animatedBackgroundStop 5s linear infinite",
        isShowDoor: true,
        Car_Status: "",
        connectState: "",
        Service_Mode: "",
        currenStatus: "",
        tValue: "",
        beforeNum: "",
        webSocketStartTime: "",
        webSocketEndTime: "",
        lockReconnect:false,
        reconnectNum: 3, // 重试次数
        alarmBell:"",
      };
    },
    watch: {
      "Car_Direction": "changeUpDown",
      "Car_Status": "changeUpDown",
      "Door_Switch_Status": "changeDoor",
      "clickTag": "createWs",
      "Fault_Status": "changeFaultStatus",
      "passengerStatus": "changePassengerStatus",
    },
    mounted() {
      this.createWs();
    },
    destroyed() {
      this.ws.close();
    },
    methods: {
      changeMute() {
        this.isSilence = !this.isSilence;
      },
      createWs() {
        this.Car_Position = "";
        this.Car_Direction = "";
        this.Door_Status = "";
        this.Door_Switch_Status = "";
        this.srcDownUp = "";
        this.bigSrcDownUp = "";
        this.animation = "animatedBackgroundStop 5s linear infinite";
        this.isShowDoor = true;
        this.Car_Status = "";
        this.passengerStatus = false; // 轿厢内是否有人
        this.Fault_Status = false;
        this.connectState = "";
        this.Service_Mode = "";
        this.currenStatus = "";
        this.tValue = "";
        this.alarmBell = "";
        if (this.ws != null) {
          this.ws.close();
        }
        this.connectState = "开始连接";
        try {
          this.ws = new WebSocket(wsUrl);
          this.initEventHandle();
        } catch (e) {
          this.reconnectWs();
        }
      },
      reconnectWs() {
        if (!this.lockReconnect) {
          this.lockReconnect = true;
          // 没连接上会一直重连，设置延迟避免请求过多
          setTimeout(() => {
            if (this.reconnectNum > 0) {
              this.reconnectNum--;
              this.createWs();
              this.lockReconnect = false;
            }
          }, reconnectInterval);
        }
      },
      initEventHandle() {
        this.ws.onclose = () => {
          this.connectState = "连接断开";
          if (!this.monitorLimit) {
            this.reconnectWs();
          } else {
            const currentTimeValue = this.$moment().valueOf();
            if (currentTimeValue < this.webSocketEndTime) {
              this.reconnectWs();
            }
          }
        };
        this.ws.onerror = () => {
          this.connectState = "连接异常";
          this.reconnectWs();
        };
        this.ws.onopen = () => {
          this.connectState = "开始认证";
          //          this.heartbeatReset() // 心跳检测重置
          //          {"t":"B00","d":{"regCode":"DT100000004031"}}
          let authProto = {
            t: "B00",
            d: {
              regCode: this.registerNo,
              //              regCode: 'ZC00000464'
            },
          };
          if (this.monitorLimit) {
            this.webSocketStartTime = this.$moment().valueOf();
            this.webSocketEndTime = this.webSocketStartTime + (this.monitorLimit * 60 * 1000);
          }
          this.ws.send(JSON.stringify(authProto)); // 发送认证信息
        };
        this.ws.onmessage = (event) => {
          this.connectState = "连接正常";
          try {
            // 如果获取到消息,心跳检测重置,拿到任何消息都说明当前连接是正常的
            //            this.heartbeatReset()
            let proto = JSON.parse(event.data);
            this.tValue = proto.t;
            this.reconnectNum = 3;
            if (this.tValue === "A09") {
              this.alarmBell = proto.d.alarmBell
              this.Car_Position = proto.d.Car_Position;
              this.Car_Direction = proto.d.Car_Direction;
              this.Door_Status = proto.d.Door_Status;
              this.Door_Switch_Status = proto.d.Door_Switch_Status;
              this.Car_Status = proto.d.Car_Status;
              this.Fault_Status = proto.d.Fault_Status;
              this.passengerStatus = proto.d.Passenger_Status;
              this.Service_Mode = proto.d.Service_Mode;
              if (this.Service_Mode === 1) {
                this.currenStatus = this.$t("monitor.status.motion");
              } else if (this.Service_Mode === 2) {
                this.currenStatus = this.$t("monitor.status.service");
              } else if (this.Service_Mode === 4) {
                this.currenStatus = this.$t("monitor.status.fire");
              } else if (this.Service_Mode === 7) {
                this.currenStatus = this.$t("monitor.status.unKnow");
              }
              this.connectState = "连接正常";
            } else if (this.tValue === "A05") {
              this.beforeNum = proto.d.beforeNum;
            }
          } catch (e) {
            // console.log(e);
          }
        };
      },
      heartbeatStart() {
        this.timeoutObj = setTimeout(() => {
          // 这里发送一个心跳,后端收到后,返回一个心跳消息,onmessage拿到返回的心跳就说明连接正常
          this.ws.send(pingProto);
        }, heartbeatTime);
      },
      heartbeatReset() {
        // console.log("reset");
        clearTimeout(this.timeoutObj);
        this.heartbeatStart();
      },
      requestData(type) {
        // console.log("请求数据：" + type);
        let requestProto = {
          type: type,
        };
        this.ws.send(JSON.stringify(requestProto));
      },
      handleSubmit() {
        this.ws.close();
      },
      changeUpDown() {
        if (this.Car_Status === 0) {
          if (this.Car_Direction === 1) {
            this.srcDownUp = "/static/images/elevator/arrow_up.png";
            this.bigSrcDownUp = "";
            this.animation = "animatedBackgroundStop 5s linear infinite";
          } else if (this.Car_Direction === 2) {
            this.srcDownUp = "/static/images/elevator/arrow_down.png";
            this.bigSrcDownUp = "";
            this.animation = "animatedBackgroundStop 5s linear infinite";
          } else {
            this.srcDownUp = "";
            this.bigSrcDownUp = "";
            this.animation = "animatedBackgroundStop 5s linear infinite";
          }
        } else {
          if (this.Car_Direction === 1) {
            this.srcDownUp = "/static/images/elevator/arrow_up.gif";
            this.bigSrcDownUp = "/static/images/elevator/arrowb_up.gif";
            this.animation = "animatedBackgroundUp 5s linear infinite";
          } else if (this.Car_Direction === 2) {
            this.srcDownUp = "/static/images/elevator/arrow_down.gif";
            this.bigSrcDownUp = "/static/images/elevator/arrowb_down.gif";
            this.animation = "animatedBackgroundDown 5s linear infinite";
          } else {
            this.srcDownUp = "";
            this.bigSrcDownUp = "";
            this.animation = "animatedBackgroundStop 5s linear infinite";
          }
        }
      },
      changeDoor() {
        if (this.Door_Switch_Status === 1) {
          //          开门
          this.isShowDoor = false;
        } else {
          this.isShowDoor = true;
        }
      },
      reConnect() {
        this.createWs();
      },
      openSingleLadderMonitor() {
        this.loading = true;
        let params = {
          "elevatorRegisterNo": this.registerNo,
        };
        this.$api.getList("/singleLadderMonitor/elevatorRegisterNo", params).then(res => {
          let elevatorId = res.data.id;
          window.open("#/singleMonitor/" + elevatorId);
        }).catch(() => {
          this.loading = false;
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      changeFaultStatus() {
        let faultInterval = setInterval(() => {
          if (this.Fault_Status) {
            this.faultImg = !this.faultImg;
          } else {
            let musicMp3 = document.getElementById("faultMp3");
            if (musicMp3){
              musicMp3.pause();
              clearInterval(faultInterval);
              this.faultImg = false;
            }
          }
        }, 500);
      },
      changePassengerStatus() {
        let b = true;
        let passengerInterval = setInterval(() => {
          if (this.Fault_Status && this.passengerStatus) {
            this.passengerImg = !this.passengerImg;
          } else {
            let musicMp3 = document.getElementById("passengerMp3");
            if (musicMp3){
              musicMp3.pause();
              clearInterval(passengerInterval);
              this.passengerImg = false;
            }
          }
        }, 500);
      },
    },
  };
</script>
<style type="text/css">

.middleAnimateArea {
  position: relative;
  background-image: url(/static/images/elevator/bgs2.jpg);
  background-size: 100%;
  background-position: 0px 0px;
  background-repeat: repeat-y;
  animation: animatedBackgroundDown 5s linear infinite;
  -ms-animation: animatedBackgroundDown 5s linear infinite;
  -moz-animation: animatedBackgroundDown 5s linear infinite;
  -webkit-animation: animatedBackgroundDown 5s linear infinite;
}

@keyframes animatedBackgroundDown {
  from {
    background-position: 0 0px;
  }
  to {
    background-position: 0 100%;
  }
}

@keyframes animatedBackgroundUp {
  from {
    background-position: 0 0px;
  }
  to {
    background-position: 0 -100%;
  }
}

@keyframes animatedBackgroundStop {
  from {
    background-position: 0 0px;
  }
  to {
    background-position: 0 0%;
  }
}

.middleElevatorBox {
  position: absolute;
  width: 340px;
  height: 490px;
  top: 15%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.alarmImage {
  width: 64px;
  border-radius: 32px;
  background: #FF4B00;
  position: absolute;
  right: 32px;
  bottom: 32px;
  text-align: center;
  padding: 8px 0;
}

.middleBox {
  width: 98px;
  height: 201px;
  top: 22%;
  overflow: hidden;
  position: absolute;
  background: url(/static/images/elevator/door8s.png);
  z-index: 23;
}

.middlleLayerArrow {
  position: absolute;
  z-index: 22;
  width: 12px;
  height: 12px;
  top: 18%;
  left: 22%;
}

.middleLayerNum {
  position: absolute;
  z-index: 22;
  top: 19%;
  left: 30%;
  color: crimson;
  font-size: 16px;
  font-family: Gill Sans, Gill Sans MT, Myriad Pro, DejaVu Sans Condensed, Helvetica, Arial, " sans-serif";
}

a {
  text-decoration: none;
  color: #000;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.middleBoxImg {
  width: 98px;
  height: 201px;
  overflow: hidden;
  margin: 3px 0px 0px 2px;
}

.middleBoxImg ul li {
  position: absolute;
}

.middleBoxImg ul li a {
  display: block;
  width: 98px;
  height: 201px;
}

.middleStatus {
  position: absolute;
  z-index: 24;
  width: 20px;
  height: 20px;
  top: 30%;
  left: 30%;
}

.middleStatusName {
  position: absolute;
  z-index: 24;
  top: 32%;
  left: 15%;
  font-size: 20px;
  color: #ffa473;
  font-family: Microsoft YaHei
}

.middleStatusButton {
  position: absolute;
  z-index: 222;
  top: 30%;
  left: 50%;
}

.middleTValueClass {
  position: absolute;
  z-index: 24;
  top: 43%;
  left: 10%;
  font-size: 20px;
  color: #ffffff;
  font-family: Microsoft YaHei
}

.middleLoadingClass {
  position: absolute;
  z-index: 24;
  top: 26%;
  left: 30%;
}

.middleBrokenClass {
  position: absolute;
  z-index: 24;
  top: 26%;
}

.middleUnlinkClass {
  position: absolute;
  z-index: 24;
  top: 33%;
  left: 5%;
}
</style>
